<template>
  <div
    v-if="Object.keys(theme).length"
    class="theme h-100"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <div class="swiper-container px-4">
      <v-row class="h-100 flex-nowrap">
        <v-col
          cols="6"
          v-for="product in products"
          :key="product._id"
          class="my-4 h-100 swiper-slide"
        >
          <span
            id="banner-container"
            class="banner custom-banner"
            v-if="product.item.banner && product.item.banner.text"
            :style="{ backgroundColor: theme.banner.container.color }"
            :key="restartAnimationKey + Math.random()"
          >
            <span class="banner-font-size" :data-product-id="product.item._id">
              <span
                :style="{ color: theme.banner.text.color }"
                id="banner-text"
                class="banner-text"
                >{{ product.item.banner.text }}</span
              >
            </span>
          </span>
          <div class="swiper-slide-content preview-item position-relative">
            <div
              v-if="product.item.show === 'video'"
              class="swiper-slide-content video-container"
            >
              <video
                width="100%"
                height="100%"
                :style="`object-fit: ${product.item.video.videoShowType} !important;`"
                :poster="product.item.video.thumbnail"
                autoplay
                muted
                loop
              >
                <source :src="product.item.video.url" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div
              v-if="product.item.show === 'youtube'"
              class="swiper-slide-content video-container"
            >
              <iframe
                :src="extractedYoutubeEmbedUrl(product.item.links[0].url)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <div class="video-overlay"></div>
            </div>

            <div
              v-else-if="product.item.show === 'image'"
              class="wrapper-image"
              :style="{
                height:
                  !product.item.title &&
                  !product.item.description &&
                  !product.item.price &&
                  !product.item.oldPrice
                    ? '100%'
                    : '',
              }"
            >
              <img
                :src="product.item.images[0].url"
                :style="`objectFit: ${product.item.imageShowType} !important`"
                class="swiper-slide-image"
              />
            </div>

            <div
              class="wrapper-content"
              v-if="
                product.item.title ||
                product.item.description ||
                product.item.price ||
                product.item.oldPrice
              "
            >
              <div
                :style="{ backgroundColor: theme.content.container.color }"
                class="card-info theme-content d-flex flex-column h-100"
              >
                <div
                  class="swiper-slide-title theme-title"
                  :data-product-id="product.item._id"
                  v-if="product.item.title"
                  :style="{ color: theme.content.title.color }"
                  id="theme-title"
                  :key="restartAnimationKey + Math.random()"
                >
                  {{ product.item.title }}
                </div>
                <div
                  class="swiper-slide-text theme-description"
                  :data-product-id="product.item._id"
                  v-if="product.item.description"
                  :style="{ color: theme.content.description.color }"
                  id="theme-description"
                  :key="restartAnimationKey + Math.random()"
                  v-html="wrapString(product.item.description, 50)"
                ></div>
              </div>
              <div
                class="prices black--text py-3 px-1"
                v-if="product.item.price || product.item.oldPrice"
                :style="{
                  backgroundColor: theme.prices.container.color,
                }"
              >
                <span
                  v-if="product.item.price"
                  :style="{
                    color: theme.prices.price.color,
                  }"
                  id="price"
                  :key="restartAnimationKey + Math.random()"
                  :data-product-id="product.item._id"
                  class="price theme-price mx-0"
                >
                  {{ product.item.price }}
                  <span class="currency h-100">
                    {{ currency }}
                  </span>
                </span>
                <span
                  v-if="product.item.oldPrice"
                  :style="{ color: theme.prices.oldPrice.color }"
                  id="old-price"
                  :key="restartAnimationKey + Math.random()"
                  class="ma-0 discount theme-old-price"
                  :data-product-id="product.item._id"
                >
                  {{ product.item.oldPrice }}
                  <span class="currency">
                    {{ currency }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: "TwoSlides",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 32px);
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  // background-color: rgba(51, 51, 51, 1);

  border-radius: 14px;
}
.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #000;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 3px 4px 10px 2px #00000040;
}

.swiper-slide-title {
  letter-spacing: 0.03em;
}

.wrapper-content {
  position: absolute;
  display: flex;
  background-color: rgba(255, 255, 255, 0.94);
  width: 100%;
  height: calc(100% - 75.8%);
  align-self: flex-end;
  // padding: 15px;
  text-align: left;
  justify-content: space-between;
}

.swiper-logo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.card-info {
  line-height: 1.4;
  height: 100%;
  width: calc(100% - 23.5%);
  flex-grow: 1;
}
.prices {
  width: 23.5% !important;
  background-color: #fdd039;
  color: #000;
}
.wrapper-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 75.8%;
  z-index: 0;
}
.swiper-slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
.custom-banner {
  width: fit-content;
  height: 10%;
  display: inline-block;
  border-radius: 14px;
  padding: 4px 12px;
  margin-left: 0;
  // left: unset !important;
  top: 16px;

  & > span {
    // background-color: #fdd039;
    color: #000;
    word-break: break-word;
    font-weight: 500;
    span {
      line-height: 1.2 !important;
    }
  }
}
.discount {
  width: max-content;
}
</style>
